@media (max-width: 768px) {

  .phone-flex-clo {
    flex-direction: column !important;
  }

  .ph-mt-10-px {
    margin-top: 10px;
  }

  nav {
    height: 100px;
    ._container {
      margin-left: 10px;
      margin-right: 10px;
    }

    button {
      margin-left: 4rem;
      width: 115px;
      height: 35px;
      font-size: 9px;
    }
  }

  .p-30-px {
    padding: 20px;
  }

  .head-star {
    width: 50px;
    height: 20px;
    top: 40px;
  }
  
  .page1-warp {
    .tron-star {
      width: 200px;
    }

    .grid-warp {

      .image-1 {
        grid-area: 10 / 1 / 26 / 17;
      }
      
      .image-2 {
        grid-area: 4/16/10/26;
      }
      
      .image-3 {
        grid-area: 8/36/14/50;
      }
      
      .image-4 {
        grid-area: 19/8/32/21;
      }
      
      .image-5 {
        grid-area: 17/29/34/40;
      }
      
      .image-6 {
        grid-area: 20/42/28/52;
      }
      
      .image-7 {
        grid-area: 35 / 1 / 51 / 10;
      }
      
      .image-8 {
        grid-area: 31/13/46/22;
      }
      
      .image-9 {
        grid-area: 38/23/51/32;
      }
      
      .image-10 {
        grid-area: 31/32/48/48;
      }
    }
  }

  .fs-30-px {
    font-size: 20px;
  }
  
  .pr-20-px {
    padding-right: 15px;
  }


  .page2-warp {
    font-size: 15px;
    padding: 0 30px;
    .btn-warp {
      width: 320px;
      height: 45px;
      font-size: 14px;

      .view-text {

        .icon-cart {
          width: 22px;
          height: 19px;
          margin-right: 10px;
        }

        .icon-go {
          width: 14px;
          height: 12px;
  
          &.clock {
            width: 19px;
            height: 18px;
          }
        }
      }
    }

    .cooperation-warp {
      .icon-ape {
        width: 70px;
        height: 22px;
        margin-right: 20px;
      }
  
      .icon-x {
        width: 6px;
        height: 6px;
        margin-right: 20px;
      }
  
      .icon-tron {
        width: 58px;
        height: 18px;
      }
    }
  }

}


@media (min-width: 1024px) {
  // .container {
  //   padding-left: 50px;
  //   padding-right: 50px;
  // }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.z-100 {
    z-index: 100;
}

.mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.w-3\/4 {
    width: 75%;
}

.w-1\/4 {
    width: 25%;
}

.w-2\/3 {
    width: 66%;
}

.w-1\/3 {
    width: 33%;
}

.bg-gray-300 {
    background-color: rgb(209 213 219);
}

.bg-sky-300 {
    background-color: rgb(125 211 252);
}

.bg-sky-500 {
    background-color: rgb(14 165 233);
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.mt-30-px {
    margin-top: 30px;
}

.mt-20-px {
    margin-top: 20px !important;
}

.mt-5-px {
    margin-top: 5px !important;
}

.w-290-px{
    width: 260px;
}

.h-300-px{
    height: 280px;
}

.ff {
    font-family: Montserrat-Regular, Montserrat;
}

.add-grey-color-100 {
    color: #666;
}

.add-grey-color-200 {
    color: #999;
}

.add-color-000 {
    color: #000;
}

.main-color {
   color: #6749DD;
}

._box_shadow {
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
    .md\:w-1\/4 {
        width: 25%;
    }

    .md\:w-1\/3 {
        width: 33.3%;
    }

    .md\:w-2\/3 {
        width: 66.6%;
    }

    .md\:w-2\/5 {
        width: 40%;
    }

    .md\:w-3\/5 {
        width: 60%;
    }
}

@media (min-width: 1024px) {

}

@media (min-width: 1280px) {

}



@font-face {
  font-family: 'Montserrat Bold';
  src: url(../fonts/Montserrat-SemiBold-9.otf);
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url(../fonts/Montserrat-Regular-8.otf);
}

.success-text {
  color: #00A57B;
}

.error-text {
  color: #D90000;
}

html {
  font-size: calc(10 * 100vw / 1440) !important;
}

body {
  font-family: 'Montserrat Regular';
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 0px !important;
}

.language {
  width: 16px;
  height: 16px;
  background: url(../img/language.png) 50% no-repeat;
  background-size: contain;
  margin-right: 8px;
}

.grid_item_img {
  width: 100%;
  height: 100%;
}

#english:hover {
  background-color: #9c27b0;
  color: white;
}

#chinese:hover {
  background-color: #9c27b0;
  color: white;
}

nav {
  height: 100px;
  ._container {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .header_img {
    width: 130px;
    height: 38px;
  }

  .wallet_img {
    width: 15px;
    height: 11px;
    margin-right: 8px;
  }

  .nft_img {
    width: 34px;
    // height: 30px;
    margin-left: 2.5rem;
  }

  .gift_img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  .no_nft_img {
    width: 180px;
    height: 180px;
  }

  .has_nft_img {
    width: 180px;
    height: 180px;
  }

  .drop_arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #fff;
    position: absolute;
    top: -9px;
  }

  ul {
    width: 100px;
  }

  .not_main {
    margin-left: 26px;
    text-align: center;
    color: #fff;
    width: 160px;
    margin-top: 5px;
  }

  button {
    margin-left: 26px;
    background: #fff;
    color:#000;
    width: 160px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 40px;
  }
}

@keyframes move {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}

.head-star {
  width: 115px;
  height: 45px;
  position: absolute;
  top: 30px;
  left: 40px;
}

.page1-warp {
	position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  .grid-warp {
    background-color: #000;
    pointer-events: none;
    position: absolute;
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
    display: grid;
    grid-template-columns: repeat(50,2%);
    grid-template-rows: repeat(50,2%);
  
    .image-1 {
      grid-area: 10 / 1 / 26 / 7;
    }
    
    .image-2 {
      grid-area: 1 / 19 / 9 / 27;
    }
    
    .image-3 {
      grid-area: 1 / 36 / 14 / 42;
    }
    
    .image-4 {
      grid-area: 13 / 11 / 32 / 18;
    }
    
    .image-5 {
      grid-area: 17 / 32 / 32 / 38;
    }
    
    .image-6 {
      grid-area: 20 / 45 / 28 / 51;
    }
    
    .image-7 {
      grid-area: 35 / 1 / 51 / 10;
    }
    
    .image-8 {
      grid-area: 38 / 14 / 46 / 22;
    }
    
    .image-9 {
      grid-area: 40 / 26 / 51 / 32;
    }
    
    .image-10 {
       grid-area: 37 / 39 / 48 / 47;
    }
    
  }

  .tron-star {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  .page1-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .4);
  }

  .down-warp {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #E14800;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon-down {
      animation: 1.5s move linear infinite;
    }
  }
}

.fs-30-px {
  font-size: 30px;
}

.fs-20-px {
  font-size: 20px;
}

.fs-18-px {
  font-size: 18px;
}

.fs-14-px {
  font-size: 14px;
}

.pr-10-px {
  padding-right: 10px;
}

.mt-10-px {
  margin-top: 10px;
}

.mr-20-px {
  margin-right: 20px;
}

.mt-90-px {
  margin-top: 90px;
}

.mt-20-px {
  margin-top: 20px;
}

.mt-30-px {
  margin-top: 30px;
}

.p-30-px {
  padding: 30px;
}

.page2-warp {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: url('../../assets/img/tron-start-2.png') no-repeat; 
  background-color: #E24900;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  position: relative;
  background-size: cover;

  .main-color {
    color: #E14800;
  }

  .btn-warp {
    width: 448px;
    height: 64px;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;

    .view-text {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-cart {
        width: 29px;
        height: 24px;
        margin-right: 20px;
      }

      .icon-go {
        width: 24px;
        height: 19px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        &.clock {
          width: 38px;
          height: 37px;
        }
      }
    }
  }

  .icon-img {
      width: 28px;
      height: 22px;
  }

  .icon-trx {
    width: 26px;
    height: 26px;
}

  .rule-warp {
    .icon-current {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .num {
      font-size: 12px;
      width: 20px;
      height: 20px;
      border: 1px solid #0C0C0C;
      border-radius: 30px;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  .cooperation-warp {
    // background-color: #E24900;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    .icon-ape {
      width: 140px;
      height: 45px;
      margin-right: 20px;
    }

    .icon-x {
      width: 11px;
      height: 12px;
      margin-right: 20px;
    }

    .icon-tron {
      width: 113px;
      height: 34px;
    }
  }
}

.error-img {
  width: 54px;
  height: 54px;
}

.type-img {
  width: 20px;
  height: 20px;
}